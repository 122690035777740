import React from 'react'
import axios from "axios"
import useWarning from './use-warning'
import useSuccess from './use-success'
import {joinAction} from '../config/index';
import RegisterForm from './register-form'
import useSpinner from '../UI/use-spinner'
import useWarningCaptcha from './use-warning-captcha'
import useCaptcha from '../UI/use-captcha'
import  '../UI/ui.css'


export default () => {

  const errMsg = <p>There is some trouble with connection.</p>
  const succesMsg = <p className='success-register'>
                          Thank You! <br/> We will contact you shortly.
                    </p>

  const [ spinner, showSpinner, hideSpinner ] = useSpinner()
  const [ modalError, showError ] = useWarning(errMsg);
  const [ modalSuccess, showSuccess ] = useSuccess(succesMsg)
  const [ warnCaptcha, showWarnCaptcha, hideWarnCaptcha ] = useWarningCaptcha()
  const [ captcha, refreshCaptcha ] = useCaptcha();

  const handleOnSubmit = (values, actions) => {
    showSpinner()
    axios({
      method: "POST",
      url: joinAction,
      data: values
    })
      .then(response => {
        hideSpinner();
        actions.setSubmitting(false);
        actions.resetForm();
        showSuccess()
      })
      .catch(error => {
        if (error.response.data !== '') {
          refreshCaptcha()
          showWarnCaptcha(error.response.data)
          hideSpinner()
          actions.setSubmitting(false)
        } else {
          hideSpinner()
          hideWarnCaptcha()
          actions.setSubmitting(false)
          showError()
          actions.resetForm()
        }
      });
  }
  return(
    <>
      {modalError}
      {modalSuccess}
      <div className='spinner-pos-register'>{spinner}</div>
          <div className="affiliates-title-wrp">
              <h2 className="title affiliates-title">Register</h2>
              <p className="title affiliates_subtitle">to intense affiliates today</p>
          </div>

          <RegisterForm handleOnSubmit={handleOnSubmit}
                        warnCaptcha={warnCaptcha}
                        captcha={captcha}/>
    </>
  )
}
