import React, { useState } from 'react'
import PassReminder from './pass-reminder'


 const usePassReminder = props => {
  const [visible, setVisible] = useState(false)
  const showReminder = () => setVisible(true)
  const hideReminder = () => setVisible(false)
  const passReminder = visible ? <PassReminder handleClickClose={()=>{
                                               hideReminder()}} /> 
                                               : null

  return [ passReminder, showReminder, hideReminder ]
}
export default usePassReminder
