import React from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import axios from "axios";
import {resetPass} from '../config/index';
import useSpinner from '../UI/use-spinner';
import useWarning from './use-warning'
import useSuccess from './use-success'
import ResetForm from './reset-form'
import  '../UI/ui.css'

export default () => {

  const errMsg = 'There is some trouble with connection.'
  const succesMsg = 'Password has been reset.'

  const [ spinner, showSpinner, hideSpinner ] = useSpinner()
  const [ modalError, showError ] = useWarning(errMsg)
  const [ modalSuccess, showSuccess ] = useSuccess(succesMsg)


  const handleOnSubmit = (values, actions) => {
    showSpinner()
    axios({
     method: "POST",
     url: resetPass,
     data: values
    })
     .then(response => {
       hideSpinner()
       showSuccess()
       actions.setSubmitting(false)
       actions.resetForm()
     })
     .catch(error => {
       showError()
       hideSpinner()
       actions.setSubmitting(false)
       actions.resetForm()
     })
  }

  return (
    <Router>
      <Switch>
        <Route path="/reset">
         <>
           {modalError}
           {modalSuccess}
           <div className='spinner-pos'>{ spinner }</div>
           <ResetForm handleOnSubmit = { handleOnSubmit } />
         </>
        </Route>
      </Switch>
    </Router>
  )
}
