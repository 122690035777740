import React, {useState} from 'react'

const useWarning = (succesMsg) => {
  const [visible, setVisible] = useState(false)
  const showMSuccess = () => setVisible(true)
  const hideSuccess = () => setVisible(false)
  const modalSuccess = visible ? (
                        <div className='bg-success'>
                          <p>{succesMsg}</p>
                          <button onClick={()=> {window.location = '/'}}
                                  className="button button-transparen"
                          >
                                  OK
                          </button>
                        </div>
                      ) : null

  return [ modalSuccess, showMSuccess, hideSuccess ]
}
export default useWarning
