import React, { useState } from 'react'

const useWarningCaptcha = props => {
const [visible, setVisible] = useState(false)
const [message, setMessage] = useState('Invalid captcha code.')
const showWarnCaptcha = (message) => {
  setVisible(true);
  setMessage(message);
}
const hideWarnCaptcha = () => setVisible(false)
const warnCaptcha = visible ?
                      <p className='warn-captcha'>{message}</p>
                      : null

  return [ warnCaptcha, showWarnCaptcha, hideWarnCaptcha ]
}
export default useWarningCaptcha
