import React from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

const validationSchemaContact = Yup.object().shape({
  firstName: Yup.string()
    .max(50, 'Must be shorter than 50')
    .required('Enter a name'),
  phone: Yup.string()
    .max(50, 'Must be shorter than 50')
    .required("Enter a phone"),
  email: Yup.string()
    .max(50, 'Must be shorter than 50')
    .email('Enter a valid email address')
    .required('Enter an email address'),
  message: Yup.string()
    .required("Enter a message")
})

export default (props) => {
  return(
      <Formik
        initialValues = {{ firstName: "",
                           phone: "",
                           email: "",
                           message: "" }}
        onSubmit= {props.handleOnSubmit}
        validationSchema={validationSchemaContact}
      >
        {({ isSubmitting }) => (
          <Form id={props.id} className="footer-form" noValidate >
          <h2 className="title footer-form-title">We’re here for you</h2>
            <div className="field-wrp">

              <div className='footer-field-wrp'>
                <Field type="text"
                       name="firstName"
                       placeholder="Full Name"
                       className="footer-field"
                />
                <ErrorMessage name="firstName"
                              render={msg => <div className="error-msg-contact"><p>{msg}</p></div>}/>
              </div>

              <div className='footer-field-wrp'>
                <Field type="text"
                       name="phone"
                       placeholder="Phone"
                       className="footer-field"
                />
                <ErrorMessage name="phone"
                              render={msg => <div className="error-msg-contact"><p>{msg}</p></div>}/>
              </div>

              <div className='footer-field-wrp'>
                <Field type="email"
                       name="email"
                       placeholder="Email"
                       className="footer-field"
                />
                <ErrorMessage name="email"
                              render={msg => <div className="error-msg-contact"><p>{msg}</p></div>}/>
              </div>

              <div className='footer-field-wrp'>
                <Field name="message"
                       component="textarea"
                       rows="6"
                       cols="30"
                       placeholder="You message"
                       className="footer-field"
                />
                <ErrorMessage name="message"
                              render={msg => <div className="error-msg-contact"><p>{msg}</p></div>} />
              </div>


            <button type="submit"
                    className="button button-transparent footer-form-submit"
                    disabled={isSubmitting}>
                    send
            </button>
          </div>

          </Form>
        )}
      </Formik>
  )
}
