import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import axios from "axios";
import {loadTestimonials} from  '../config/index'
import './testimonials.css'


export default function Testimonials(){


  console.log('test')
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState();
  const [isLoading, setLoading] = React.useState(true)


  React.useEffect(() => {
    
    document.getElementById('faq').style.display = 'none';
    document.getElementById('commissions').style.display = 'none';
    document.getElementById('why').style.display = 'none';
    document.getElementById('our').style.display = 'none';
    document.getElementById('video').style.display = 'none';

    axios({
      method: "GET",
      url: loadTestimonials,
    })
      .then(response => {
        setLoading(false)
        setData(response.data)
      })
      .catch(error => {
        setLoading(false)  
        setError(error)
      })
  }, [])

  const items = ['', '', '', '']
  return(
          <section>
            <div className='testimony-wrap'>
              <header className='title-block'>
                <span className="title-block_line title-block_line-left"></span>
                  <h2 className='title title-bloc_title'>TESTIMONIALS</h2>
                <span className="title-block_line title-block_line-right"></span>
              </header>

              <p className='error'>{error && error.message}</p>

              <div className='col-md-12 p-0 d-flex flex-column flex-md-row md-flex justify-content-center'>
                  <div className='col-md-6 col-12 p-0'>
                        {isLoading ? (
                          <ul>
                            {items.map((item, index) => (
                              <li key={index} className='mt-3 mb-3 testimonials-card d-flex flex-column align-items-center'>
                                  <div className='d-flex justify-content-center mb-4 p-0'
                                        style={{width: '50%', height: 20, background: '#f7f7f7', borderRadius: 20}}></div>
                                      <p style={{width: '100%', height: 90, background: '#f7f7f7', borderRadius: 10}}>

                                  </p>
                                  <p style={{width: '80%', height: 20, background: '#f7f7f7', borderRadius: 20}}></p>
                              </li>
                            ))}
                          </ul>
                        ):  (
                
                          <ul>
                            {data.map(({id, src, title, description, href, background},index) => (
                                (((index) % 2) === 0) ? (
                                  <li key={index} className='mt-3 mb-3 testimonials-card d-flex flex-column align-items-center'>
                                      <div style={{background}}
                                        className='d-flex justify-content-center mb-4 p-0 '>
                                        <img key={id}
                                          src={src}
                                          alt={description}
                                          style={{maxWidth: '100%',  maxHeight: '80px', margin: 8}}
                                          />
                                        </div>
                                        <p>"{title}"</p>
                                          <a href={href}>{description}</a>
                                  </li>
                            ) : null
                          ))}
                          </ul>)}
                      </div>
                
                  <div className='col-md-6 col-sm-12 col-12 p-0'>
                    {isLoading ? (
                      <ul>
                        {items.map((item, index) => (
                          <li key={index} className='mt-3 mb-3 testimonials-card d-flex flex-column align-items-center'>
                              <div className='d-flex justify-content-center mb-4 p-0'
                                  style={{width: '50%', height: 20, background: '#f7f7f7', borderRadius: 20}}></div>
                                <p style={{width: '100%', height: 90, background: '#f7f7f7', borderRadius: 10}}>

                              </p>
                              <p style={{width: '80%', height: 20, background: '#f7f7f7', borderRadius: 20}}></p>
                          </li>
                        ))}
                      </ul>
                    ):  (

                    <ul>
                      {data.map(({id, src, title, description, href, background}, index) => (
                          (((index) % 2) !== 0) ? (
                        <li key={index} className='mt-3 mb-3 testimonials-card d-flex flex-column align-items-center'>
                            <div style={{background}}
                              className='d-flex justify-content-center mb-4 p-0 '>
                              <img key={id}
                                src={src}
                                alt={description}
                                style={{maxWidth: '100%',  maxHeight: '80px', margin: 8}}
                                />
                              </div>

                              <p>"{title}"</p>
                                <a href={href}>{description}</a>
                        </li>

                      ) : null
                    ))}
                    </ul>)} 
                  </div>
              </div>
            </div>
          </section>
  
  )
}