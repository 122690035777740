import React, { useState, useEffect } from 'react';
import axios from "axios";
import ContactForm from './contact-form';
import {contactAction} from '../config/index';
import useWarning from './use-warning';
import useSuccess from './use-success'
import useSpinner from '../UI/use-spinner';
import  '../UI/ui.css'

export default () => {
  const errMsg = <p>There is some trouble with connection.</p>
  const succesMsg = <p className='success-register'>
                            Thank You! <br/> We will contact you shortly.
                    </p>

  const [ modalError, showError, hideError, setOnClose ] = useWarning(errMsg);
  const [ modalSuccess, showSuccess, setOnCloseSuccsess ] = useSuccess(succesMsg)
  const [ spinner, showSpinner, hideSpinner ] = useSpinner();
  const [ isVisibleForm, setVisibleForm] = useState(true);

  useEffect(() => {
    setOnClose(handleClickClose)
    setOnCloseSuccsess(handleClickClose)
  });

  const handleClickClose = ()=>{
    setVisibleForm(true)
    hideError()
  }

  const handleOnSubmit = (values, actions) => {
    showSpinner()
    axios({
     method: "POST",
     url: contactAction,
     data: values
    })
     .then(response => {
       hideSpinner()
       showSuccess()
       setVisibleForm(false)
       actions.setSubmitting(false)
       actions.resetForm()
     })
     .catch(error => {
       hideSpinner()
       setVisibleForm(false)
       showError()
       actions.setSubmitting(false)
       actions.resetForm()
     })
  }
  return (
    <>
      <div className='spinner-pos-contact'>{spinner}</div>

      {isVisibleForm ? <ContactForm handleOnSubmit = { handleOnSubmit } />
      : <>
          {modalError}
          {modalSuccess}
        </>
      }
    </>
  )
}
