import React, { useState } from 'react'

let close = null;
const useWarning = (errMsg, props) => {
  const [visible, setVisible] = useState(false)
  const showError = () => setVisible(true)
  const hideError = () => setVisible(false)
  const setOnClose = (onClose) => {
    close = onClose;
  }
  const modalError = visible ? (
                    <div className='err-contact'>
                        {errMsg}
                        <button onClick={()=> {close()}}
                                className="button button-transparen"
                        >
                                OK
                        </button>

                    </div>
                    ) : null

  return [ modalError, showError, hideError, setOnClose ]
}
export default useWarning
