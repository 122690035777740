import React from 'react';
import axios from "axios";
import LoginForm from './login-form';
import useSpinner from '../UI/use-spinner';
import {loginAction} from '../config/index';
import useWarning from './use-warning';
import usePassReminder from './use-fgt-pass'
import  '../UI/ui.css'


export default () => {

  const errMsg = <p>There was an error with your <br /> E-Mail/Password combination.</p>

  const [ spinner, showSpinner, hideSpinner ] = useSpinner();
  const [ modalError, showError ] = useWarning(errMsg);
  const [ passReminder, showReminder ] = usePassReminder();

  const handleOnSubmit = (values, actions) => {
    showSpinner()
    axios({
     method: "POST",
     url: loginAction,
     data: values
    })
     .then(response => {
       console.log('1')
       hideSpinner()
       actions.setSubmitting(false)
       actions.resetForm()
       window.location = response.data.data

     })
     .catch(error => {
       console.log(values)
       hideSpinner()
       showError()
       actions.setSubmitting(false)
       actions.resetForm()
     })
  }
  return (
    <>

      <div className='spinner-pos'>{ spinner }</div>
      { modalError }
      { passReminder }

      <button className="header-forgot-password"
              onClick = { () => { showReminder() } }>
              Forgot Password?
      </button>
      <LoginForm handleOnSubmit = { handleOnSubmit } />
    </>
  )
}
