import React from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

const validationSchema = Yup.object().shape({

  name: Yup.string()
    .max(50, 'Must be shorter than 50'),

  password: Yup.string()
      .test('passwords-match', 'Passwords don`t match', function(value) {
      return this.parent.password === value;
      })
      .required("Confirm password field is necessary")
})

export default (props) => {
  return(
      <Formik
        initialValues = {{ name: "",
                           password: "" }}
        onSubmit= {props.handleOnSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form id={props.id} className="login-form" noValidate >
              <div className="login-filed-wrp">
                <label htmlFor="name">E-Mail</label>
                <Field type="name"
                       name="name"
                       className='login-field login-name'/>
                     <ErrorMessage name="email"
                              render={msg => <div className="error-msg"><p>{msg}</p></div>}/>
              </div>

              <div className="login-filed-wrp">
                  <div className="login-forgot-password-wrp">
                      <label htmlFor="password">password</label>
                  </div>
                  <Field type="password"
                         name="password"
                         className="login-field login-password" />
                  <ErrorMessage name="password"
                                render={msg => <div className="error-msg"><p>{msg}</p></div>}/>
              </div>
              <button type="submit"
                      className="button button-transparent"
                      disabled={isSubmitting}>
                      login now
              </button>

          </Form>
        )}
      </Formik>
  )
}
