import React, { useState } from 'react'

const useWarning = (errMsg) => {
  const [visible, setVisible] = useState(false)
  const showError = () => setVisible(true)
  const hideError = () => setVisible(false)
  const modalError = visible ? (
                        <div className='bg-warning-on'>
                          <p>{errMsg}</p>
                          <button onClick={ ()=>{ hideError() } }
                                  className="button button-transparen">
                                  OK
                          </button>
                        </div>
                    ) : null

  return [ modalError, showError, hideError ]
}
export default useWarning
