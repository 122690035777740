import React, { useState } from 'react'

const useWarning = (succesMsg) => {
  const [visible, setVisible] = useState(false)
  const showError = () => setVisible(true)
  const hideError = () => setVisible(false)
  const modalError = visible ? (
                      <div className='bg-register'>
                       <div className='bg-warning-register'>
                         {succesMsg}
                         <button onClick={()=> {window.location.reload(true)}}
                                 className="button button-transparen">
                                 OK
                         </button>
                        </div>
                      </div>
                      ) : null

  return [ modalError, showError, hideError ]
}
export default useWarning
