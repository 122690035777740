import React from 'react'
import { BrowserRouter as Router, Route} from "react-router-dom";
import ReactDOM from 'react-dom';
import './index.css';
import './normilize.css'
import LoginForm from './login';
import RegisterForm from './register';
import ContactForm from './contact';
import ConfirmSuccess from './confirmation';
import ResetComponent from './reset'
import Testimonials from './testimonials/index';


ReactDOM.render(
  <React.StrictMode>
    <LoginForm id = 'fs-frm-log-mbl' />
  </React.StrictMode>,
  document.getElementById('login-form-rct-mbl')
);
ReactDOM.render(
  <React.StrictMode>
    <LoginForm id = 'fs-frm-log' />
  </React.StrictMode>,
  document.getElementById('login-form-rct')
);
ReactDOM.render(
  <React.StrictMode>
    <RegisterForm id="fs-frm-register" />
  </React.StrictMode>,
  document.getElementById('affiliate-form')
);
ReactDOM.render(
  <React.StrictMode>
    <ContactForm id="fs-frm-contact" />
  </React.StrictMode>,
  document.getElementById('contact-form')
);
ReactDOM.render(
  <React.StrictMode>
    <ConfirmSuccess />
  </React.StrictMode>,
  document.getElementById('confirm-wrp')
);
ReactDOM.render(
  <React.StrictMode>
    <ResetComponent id="frm-reset" />
  </React.StrictMode>,
  document.getElementById('reset-form')
);
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Route path="/testimonials">
          <Testimonials id="frm-testimonials" />
      </Route>
    </Router>
  </React.StrictMode>,
  document.getElementById('testimonials')
);
