import React from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";


function ConfirmSuccess() {
  return (
    <Router>
      <Switch>
        <Route path="/confirm">
          <div className='success-confirm'><p>Success!</p></div>
        </Route>
      </Switch>
    </Router>
  );
}

export default ConfirmSuccess;
