import React, { useState } from 'react'
import axios from "axios";
import {captchaUrl} from '../config/index';
import {captchaRefreshUrl} from '../config/index'
import useWarning from './use-warning'

export default (props) => {

  const [ data, setData ] = useState(captchaUrl +"&"+ Math.random())
  const [ warnCaptcha, showWarnCaptcha ] = useWarning()

  const handleGetData = () => {
    axios({
       method: "GET",
       url: captchaRefreshUrl,
       data: data
    })
     .then(response => {
       setData( response.data )
     })
     .catch(error => {
       showWarnCaptcha()
     })
  }

  return(

    <>
      {warnCaptcha}
      <div className='cpch affiliates-field_cptch'>
        <img src={data}
             alt='error'
        />
      </div>
      <p className='getCode' onClick={handleGetData}>Get a new code</p>
    </>
  )
}
