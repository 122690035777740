import React, { useState } from 'react'

let close = null;
const useWarning = (succesMsg) => {
  const [visible, setVisible] = useState(false)
  const showSuccess = () => setVisible(true)
  const setOnCloseSuccsess = (onClose) => {
    close = onClose;
  }
  const modalSuccess = visible ? (
                      <div className='err-contact'>
                          {succesMsg}
                          <button onClick={()=> {close()}}
                                  className="button button-transparen"
                          >
                                  OK
                          </button>
                      </div>
                      ) : null

  return [ modalSuccess, showSuccess, setOnCloseSuccsess ]
}
export default useWarning
