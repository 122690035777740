import React from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik"
import '../forms.css'
import { validationSchema } from '../utility/validation'

export default (props) => {

  return(
    <Formik
      initialValues = {{ companyName:"",
                         email: "",
                         name: "",
                         lastName: "",
                         password: "",
                         confirmPassword: "",
                         mobile: "",
                         website: "",
                         captchaURL: ""
                      }}
      onSubmit= {props.handleOnSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form id={props.id} className="affiliates_form" noValidate >

            <div className='affiliates-field'>
              <Field id="email"
                     type="email"
                     name="email"
                     placeholder="E-mail"
                     className="affiliates-field-ent" />
              <ErrorMessage name="email"
                            render={msg => <div className="error-msg-register"><p>{msg}</p></div>}/>
            </div>

            <div className='affiliates-field'>
              <Field id="name"
                     type="text"
                     name="companyName"
                     placeholder="Company name"
                     className="affiliates-field-ent" />
                   <ErrorMessage name="company"
                            render={msg => <div className="error-msg-register"><p>{msg}</p></div>}/>

            </div>

              <div className='affiliates-field'>
                <Field id="name"
                       type="text"
                       name="name"
                       placeholder="First name"
                       className="affiliates-field-ent" />
                     <ErrorMessage name="name"
                              render={msg => <div className="error-msg-register"><p>{msg}</p></div>}/>
              </div>

              <div className='affiliates-field'>
                  <Field id="lastName"
                         type="text"
                         name="lastName"
                         placeholder="Last name"
                         className="affiliates-field-ent" />
                <ErrorMessage name="lastName"
                              render={msg => <div className="error-msg-register"><p>{msg}</p></div>}/>
              </div>

              <div className='affiliates-field'>
                  <Field id="header-password"
                         type="password"
                         name="password"
                         placeholder="Password"
                         className="affiliates-field-ent" />
                <ErrorMessage name="password"
                              render={msg => <div className="error-msg-register"><p>{msg}</p></div>}/>
              </div>

              <div className='affiliates-field'>
                  <Field id="confirmPassword"
                         type="password"
                         name="confirmPassword"
                         placeholder="Retype Password"
                         className="affiliates-field-ent" />
                  <ErrorMessage name="confirmPassword"
                                render={msg => <div className="error-msg-register"><p>{msg}</p></div>}/>
              </div>

              <div className='affiliates-field'>
                  <Field id="mobile"
                         type="text"
                         name="mobile"
                         placeholder="Phone"
                         className="affiliates-field-ent" />
                <ErrorMessage name="mobile"
                              render={msg => <div className="error-msg-register"><p>{msg}</p></div>}/>
              </div>

              <div className='affiliates-field'>
                  <Field id="website"
                         type="text"
                         name="website"
                         placeholder="Website"
                         className="affiliates-field-ent" />
                  <ErrorMessage name="website"
                                render={msg => <div className="error-msg-register"><p>{msg}</p></div>}/>
              </div>

              <div className='affiliates-field'>
                <Field name="captchaURL"
                       type="text"
                       className='affiliates-field-ent'
                       placeholder="Please type the following word"/>
                <ErrorMessage name="captchaURL"
                              render={msg => <div className="error-msg-register"><p>{msg}</p></div>}/>
              </div>




              {props.captcha}
              {props.warnCaptcha}


              <button type="submit"
                      className="button affiliates-form-submit"
                      disabled={isSubmitting}>
                      join
              </button>

        </Form>
      )}
    </Formik>
  )
}
