
import * as Yup from "yup"

export const validationSchema = Yup.object().shape({
  company: Yup.string()
    .max(50, 'Must be shorter than 50'),

  email: Yup.string()
    .max(50, 'Must be shorter than 50')
    .email('Enter a valid email address')
    .required('Enter an email address'),

  name: Yup.string()
    .max(50, 'Must be shorter than 50')
    .required('Enter last name'),

  lastName: Yup.string()
    .max(50, 'Must be shorter than 50')
    .required('Enter last name'),

  mobile: Yup.string()
    .max(50, 'Must be shorter than 50')
    .required('A phone number is requireds'),

  website: Yup.string()
    .max(50, 'Must be shorter than 50')
    .required('Enter URL'),

  password: Yup.string()
    .min(6, 'Enter at least 6 characters')
    .required("Password field is necessary"),

  confirmPassword: Yup.string()
      .min(6, 'Seems a bit short...')
      .test('passwords-match', 'Passwords don`t match', function(value) {
      return this.parent.password === value;
      })
      .required("Confirm password field"),

  captchaURL: Yup.string()
  .required('Type text from image'),

})
