import React, {useState} from 'react'
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {restorePasswordAction} from '../config/index';
//import useErrMsg from '../UI/use-errMsg'
import useWarning from './use-warning'

const validationSchema = Yup.object().shape({

email: Yup.string()
  .email('Enter valid email')
  .required('Required'),
})

export default (props) => {

  const errMsg = <p>Server not responding</p>
  const successMsg = 'We sent you instructions on email.'

  const [ modalError, showError ] = useWarning(errMsg);
  const [success, setSuccess] = useState(false)

  const handleOnSubmit = (values, actions) => {
    axios({
      method: "POST",
      url: restorePasswordAction,
      data: values
    })
      .then(response => {
        actions.setSubmitting(false);
        actions.resetForm();
        setSuccess(true)
      })
      .catch(error => {
        actions.setSubmitting(false)
        showError()
      });
  }

  return(
    <>
      <div>{modalError}</div>
      <div className='reminder'>
          <Formik
            initialValues = {{ email: "" }}
            onSubmit= {handleOnSubmit}
            validationSchema={validationSchema}
          >
          {({ isSubmitting }) => (
            <Form id="fs-frm-reminder" className="remind-form" noValidate >

                {!success &&(
                  <div className="login-filed-wrp">
                    <label htmlFor="name">Email</label>
                    <Field id="header-email"
                           type="input"
                           name="email"
                           className='login-field login-email'/>
                         <ErrorMessage name="email"
                                  render={msg => <div className="error-msg"><p>{msg}</p></div>}/>
                  </div>
                )}
                {success &&(
                  <div className='success-notify-reset-row'>
                    <p className='succesMsg'>{successMsg}</p>
                    <button className=" button button-transparent"
                            onClick={() => {props.handleClickClose()}}>
                            OK
                    </button>
                  </div>
                )}
                <div className='btn-set-row'>
                  {!success &&(
                    <>
                      <button type="submit"
                              className="button button-transparent"
                              disabled={isSubmitting}>
                              SEND
                      </button>
                      <button className='reminder-btn'
                              onClick={() => {props.handleClickClose()}}>
                              Back to login?
                      </button>
                    </>
                  )}

                </div>

            </Form>
          )}
          </Formik>
      </div>
    </>
  )
}
