import React from 'react'
import './spinner.css'

export default () =>{
  return(
    <div className='spinner'>
      <div className="loader"></div>
    </div>

  )
}
