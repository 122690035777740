import React, { useState } from 'react'

 const useWarning = (props) => {
  const [visible, setVisible] = useState(false)
  const showError = (msg) => setVisible(true)
  const hideError = () => setVisible(false)
  const modalError = visible ? (
                        <div className='bg-warning-on'>
                          <p>Invalid captcha code.</p>
                          <button onClick={ ()=>{ hideError() } }
                                  className="button button-transparen">
                                  OK
                          </button>
                        </div>
                    ) : null


  return [ modalError, showError, hideError ]
}
export default useWarning
