import React from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import '../forms.css'

const validationSchema = Yup.object().shape({
  password: Yup.string()
      .required("Password field is necessary"),
  confirmPassword: Yup.string()
      .test('passwords-match', 'Passwords don`t match', function(value) {
      return this.parent.password === value;
      })
      .required("Confirm password field is necessary")
})

export default (props) => {

  return(
  <>
    <Formik
      initialValues = {{ password: "",
                         confirmPassword: ""
                      }}
      onSubmit= { props.handleOnSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form id={props.id} className="login-reset" noValidate >

              <div className="login-filed-wrp">
                  <div className="reset-password">
                      <label htmlFor="password">password</label>
                  </div>
                  <Field type="password"
                         name="password"
                         className="login-field login-password" />
                  <ErrorMessage name="password"
                                render={msg => <div className="error-msg"><p>{msg}</p></div>}/>
              </div>

              <div className='login-filed-wrp'>

              <div className="reset-password">
                  <label htmlFor="password">Confirm Password</label>
              </div>

                <Field type="password"
                       name="confirmPassword"
                       className="login-field login-password" />
                <ErrorMessage name="confirmPassword"
                              render={msg => <div className="error-msg"><p>{msg}</p></div>}/>
              </div>

              <button type="submit"
                      className="button button-transparent"
                      disabled={isSubmitting}>
                      RESET PASSWORD
              </button>

        </Form>
      )}
    </Formik>
</>

  )
}
