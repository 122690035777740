import React, { useState } from 'react'
import Captcha from './captcha'

 const useCaptcha = (props) => {

  const [counter, setCounter] = useState(1)

  const refreshCaptcha = () => {
    setCounter(counter + 1);
  }

  const captcha = counter % 2 ? <Captcha/> : <><Captcha/></>

  return [ captcha, refreshCaptcha ]
}

export default useCaptcha
